.input-group > .btn-outline-secondary {
    color: $primary;
    border-color: #dedede;
    min-height: 2.125rem;
}

.input-group > .form-control {
    border-color: #dedede;
    background-color: #f2f2f2;
    font-size: .9em;
    min-height: 2.125rem;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: .0625rem;
    border-bottom-left-radius: .0625rem;
    min-height: 2.125rem;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text, 
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), 
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    min-height: 2.125rem;
    font-size: .9em;
    border-color: #dedede;
    border-top-left-radius: 0;
    border-top-right-radius: .0625rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: .0625rem;
}

.input-group {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #999;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #999;
      }
}