.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
    background-color: $primary;
    color: white;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: transparent;
    color: $primary;  
}

.ng-select .ng-select-container {
    background-color: #f2f2f2;
}

.ng-select-container:not(:disabled):active, .ng-select-container:not(:disabled):focus {
    border: none;
    box-shadow: 0 0 0 0.125rem #fa5927;
    background-color: #fff;
    outline: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
    border: .0625rem solid #dedede;
    box-shadow: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-bottom: 5px;
    color: white;
    background-color:$primary;
    border-radius: 2px;
    margin-right: 5px;
}

.ng-select.ng-select-multiple{
    font-size: .9em;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #f2f2f2;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: $danger;
}

.ng-select.ng-select-opened>.ng-select-container {
    background: #fff;
    border-color: #dedede;
}

.ng-select .ng-select-container {
    background-color: #f2f2f2;
    border-radius: 2px;
    border: 1px solid #dedede;
    min-height: 2.125rem;
    -webkit-box-align: center;
    align-items: center;
}

.ng-select.ng-select-single .ng-select-container {
    font-size: .9em;
    height: 2.125rem !important;
}

.ng-select .ng-select-container:hover {
    box-shadow: none;
}
.ng-select.ng-select-disabled>.ng-select-container {
    border: none;
    background-color: transparent;
}
.ng-select.ng-select-disabled>.ng-select-container .ng-arrow-wrapper{
    display: none;
}
.ng-select.ng-select-disabled>.ng-select-container .ng-value-container{
    padding-left: 0;
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
    color: #333;
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container{
    border: none;
    background-color: transparent;
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-arrow-wrapper{
    display: none;
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container{
    padding-left: 0;
}