/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ng2-slim-loading-bar/style.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../src/assets/appkit/appkit.min.css";
@import "../src/assets/appkit/appkit.bright.orange.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.min.css";
@import "../src/assets/styles/search-icon.scss";
@import "../src/assets/styles/tables.scss";
@import "../src/assets/styles/logos.scss";
@import "../src/assets/styles/actions.scss";
@import "../src/assets/styles/pagination.scss";
@import "../src/assets/styles/forms.scss";

$body-bg: #FFFFFF;
$primary: #d04a02;
$danger: #ff4136;
$secondary: #6c757d;
$light-grey: rgb(234, 234, 234);
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "light-grey": $light-grey
);

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../src/assets/styles/boostrap-nav-color.scss";
@import "../node_modules/bootstrap/scss/mixins/_buttons.scss";
@import "../src/assets/styles/required-label.scss";
@import "../src/assets/styles/ng-select.scss";
@import "../src/assets/styles/datepicker.scss";
@import "../src/assets/styles/file-input.scss";

$btn-padding-x-xs: 0.2rem !default;
$btn-padding-y-xs: 0 !default;
$input-btn-line-height-xs: 1.1 !default;

.btn.btn-table-icon {
  @include button-size($btn-padding-y-xs,
    $btn-padding-x-xs,
    $font-size-sm,
    $btn-border-radius-sm );
  width: 1.5rem;
  height: 1.5rem;
}

html,
body {
  height: 100%;
  font-size: 100%;
}

.error-message {
  color: #e0301e;
  font-size: 0.75rem;
  margin-top: 0.3rem;
  // position: absolute;
}

app-filters {
  margin-top: 6px;
}

.goback {
  font-size: 14px;
  height: 30px;
  margin-top: 50px;
  cursor: pointer;
}

.goback:hover {
  text-decoration: underline;
}

.titolo {
  font-size: 30px;
  font-weight: 600;
  height: 60px;
}

th {
  font-weight: bold;
  color: #2d2d2d !important;
  background-color: white !important;
  border-bottom: 0px !important;
}

tbody {
  border: transparent;
}

thead {
  border-bottom: none !important;
}

table tr:nth-child(odd) td {
  padding-top: 5px;
  padding-bottom: 5px;

}

table tr:nth-child(even) td {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #F3F3F3;
}

table tr:hover {
  background-color: transparent;
  border-color: 1px solid #20376C;
}

.buttonInnoHub {
  color: white;
  background-color: #20376C;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  font-size: 14px;
}

.buttonInnoHubMobile {
  color: white;
  background-color: #20376C;
  border-radius: 5px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  font-size: 12px;
}

.buttonInnoHubMobile :hover {
  cursor: pointer;
}

.buttonInnoHub:hover {
  cursor: pointer;
}

.buttonInnoHub:disabled {
  color: white;
  background-color: gray;

}

.buttonInnoHub_inverse:hover {
  cursor: pointer;
}

.buttonInnoHub_inverse {
  color: #20376C !important;
  background-color: white;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  border: 1px solid #20376C;
  font-size: 14px;
}

.buttonInnoHub_inverseMobile:hover {
  cursor: pointer;
}

.buttonInnoHub_inverseMobile {
  color: #20376C !important;
  background-color: white;
  border-radius: 5px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
  border: 1px solid #20376C;
  font-size: 12px;
}

.buttonInnoHubTableAction {
  width: 30px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  background-color: #20376C;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.desfa-logo-grid {
  background-image: url("/assets/images/logos/DESFA\ LOGO-01\ 1.png");
  display: inline-block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 8rem;
  height: 3rem;
}

.navbar {
  background-color: rgba(255, 255, 255, 0.8);
}

.a-text-input {
  border-radius: 5px;
  background-color: white;
}

.center-cropped {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: none;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  height: 300px;
  width: 100%;
}

.center-cropped-detail {
  object-fit: cover;
  /*Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  height: 250px;
  width: 100%;
}

.center-cropped-event {
  border-radius: 15px;
  object-fit: none;
  /* Do not scale the image */
  object-position: center;
  /* Center the image within the element */
  height: 150px;
  width: 100%;
}

.allegati {
  border: 1px solid #203570;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  border-radius: 5px;
}

.allegati:hover {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.btn_contact {
  color: white;
  background-color: #20376C;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
}

.menu-option {
  color: #474747;
  text-decoration: none;
  margin-right: 15px;
  margin-top: 8px;
  padding-right: 15px;
  cursor: pointer;

  &.right-border {
    border-right: 1px solid black;
  }
}

.menu-option-mobile {
  color: #474747 !important;
  text-decoration: none !important;
  margin-top: 10px !important;
  margin-left: 60% !important;
  cursor: pointer !important;
}

.login-option {
  margin-right: 20px;
  margin-top: 5px;
  color: black
}
