ngb-pagination ::ng-deep ul>li:not(.active)>a {
    border-radius: 0.125rem !important;
    align-items: center !important;
    cursor: pointer !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .75rem !important;
    font-weight: 500 !important;
    padding: 0.1875rem 0.625rem !important;
    line-height: 1.5 !important;
    font-family: PwC Helvetica Neue !important;
    color: #D04A02 !important;
}

ngb-pagination ::ng-deep ul>li.active>a {
    border-radius: 0.125rem !important;
    align-items: center !important;
    cursor: pointer !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .75rem !important;
    font-weight: 500 !important;
    padding: 0.1875rem 0.625rem !important;
    line-height: 1.5 !important;
    font-family: PwC Helvetica Neue !important;
    background-color: #D04A02 !important;
    border-color: #D04A02 !important;
}

ngb-pagination ::ng-deep ul>li.disabled>a {
    border-radius: 0.125rem !important;
    align-items: center !important;
    cursor: pointer !important;
    display: inline-flex !important;
    justify-content: center !important;
    font-size: .75rem !important;
    font-weight: 500 !important;
    padding: 0.1875rem 0.625rem !important;
    line-height: 1.5 !important;
    font-family: PwC Helvetica Neue !important;
    color: #6c757d !important;
}