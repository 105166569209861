.no-box-shadow {
    box-shadow: none !important;
}

.form-control,
.btn {
  font-size: 0.875rem;
}

// Fix after bootstrap v5 update

.form-control, .form-control:focus {
  background-color: #fff !important;
}

.form-control-plaintext {
  font-size: 1rem;
}