th{
    font-weight: bold;
    color: #2d2d2d !important;
    background-color: #e0e0e0 !important;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: none!important;
}

th[sortable] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

th[sortable].desc:before, th[sortable].asc:before {
    content: '';
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC') no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: left;
}

th[sortable].desc:before {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.highlightTerm{
    font-weight: bold;
    color: #D04A02;
}

table {
    font-size: .875rem;
}

table tr td:last-child {
    white-space: nowrap;
    width: 1px;
}

// Fix after bootstrap v5 update
.table thead {
    vertical-align: bottom;
    border-bottom: 2px solid hsl(0, 0%, 87%);
  }
  
  .table th {
    vertical-align: bottom;
  } 
  
  .table-sm>:not(caption)>*>* {
    padding: 0.3rem;
  }