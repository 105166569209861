.pwc-logo-grid {
  background-image: url(../appkit/assets/pwc-logo-dark.1bba3afba56a5ebe2c953f943091a198.svg);
  display: inline-block;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 5rem;
  height: 3.75rem;
}
.pwc-logo-grid.sm {
  width: 2rem;
  height: 1.5rem;
}
.pwc-logo-grid.md {
  width: 2.5rem;
  height: 1.875rem;
}
.pwc-logo-grid.lg {
  width: 6.25rem;
  height: 4.6875rem;
}

.licensee-logo {
  max-width: 5rem;
  height: 3.75rem;
}
